
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class CopyVenueForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      {
        name: 'direction',
        type: InputType.Select,
        label: 'sync.form.direction',
        rules: 'required',
        items: this.directions,
      },
      { name: 'newName', type: InputType.Text, label: 'venue.form.newName' },
      { name: 'asSlave', type: InputType.Checkbox, label: 'venue.form.asSlave' },
    ];
  }

  get directions() {
    const items = ['d2d', 'd2p', 'p2d', 'p2p'];
    return items.map((val: string) => ({
      text: this.$t(`sync.directions.${val}`),
      value: val,
    }));
  }
}
