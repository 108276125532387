
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import SyncForm from '@/components/sync/SyncForm.vue';
import SyncApiService from '@/api/http/SyncApiService';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import CopyVenueForm from '@/components/venue/form/CopyVenueForm.vue';

@Component({
  components: { CopyVenueForm, VRangePicker, VWrapper },
})
export default class CopyVenue extends mixins(Notification) {
  public $refs!: {
    form: InstanceType<typeof SyncForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get venue() {
    return this.$route.params.id;
  }

  public save(type: 'vanue' | 'venue-only') {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (!res) {
        this.notifyError('notification.form');
        return;
      }

      const api: SyncApiService = new SyncApiService();
      const formData = { ...this.$refs.form.getData(), venue: this.venue };

      const notifySuccess = () => this.notifyInfo('venue.notification.copyRunning');
      const notifyError = (err: Error) => {
        console.error(err);
        this.notifyInfo('venue.notification.copyError');
      };

      const action = type === 'vanue' ? api.copyVenue(formData) : api.copyVenueOnly(formData);

      action.then(notifySuccess).catch(notifyError);
    });
  }
}
